import { createContext, useContext, useMemo, useState } from "react";

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  // const [theme, setTheme] = useState("dark");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const value = useMemo(() => ({ theme, setTheme }), [theme]);

  const handleThemeChange = () => {
    // const newTheme = theme === "dark" ? "light" : "dark";
    // setTheme(newTheme);
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <ThemeContext.Provider value={{ ...value, theme, handleThemeChange }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
